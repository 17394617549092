import React from 'react';
import { motion } from 'framer-motion'
import { Link } from 'gatsby';

const variants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 50 },
}

const transition = {
    duration: 1,
}

const defaultTransitionSpeed = 0.2;

interface Props {
    children: React.ReactNode;
    transitionSpeed?: number;
}

const FadeInWrapper = (props: Props): JSX.Element => {
    const transitionSpeed = props.transitionSpeed ?? defaultTransitionSpeed;

    const children = React.Children.map(props.children, (child, index) => {
        try {
            const htmlChild = child as JSX.Element;
            let moddedChild = htmlChild;
            switch (htmlChild.type) {
                case 'p':
                    moddedChild = (<motion.p initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'h1':
                    moddedChild = (<motion.h1 initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'h2':
                    moddedChild = (<motion.h2 initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'h3':
                    moddedChild = (<motion.h3 initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'h4':
                    moddedChild = (<motion.h4 initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'h5':
                    moddedChild = (<motion.h5 initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'h6':
                    moddedChild = (<motion.h6 initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'ul':
                    moddedChild = (<motion.ul initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'li':
                    moddedChild = (<motion.li initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'a':
                    moddedChild = (<motion.a initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'img':
                    moddedChild = (<motion.img initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                case 'div':
                    moddedChild = (<motion.div initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}} {...htmlChild.props} />);
                    break;
                default:
                    moddedChild = (<motion.div initial='hidden' animate='visible' variants={variants} transition={{...transition, delay: transitionSpeed * index}}>{htmlChild}</motion.div>);
                    break;
            }
            return moddedChild;
        } catch (error) {
            return child;
        }
    });
    return (<>{children}</>);
};

export default FadeInWrapper;