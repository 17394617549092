import React from 'react';
import '../../styles/global/global.css';
import * as styles from '../../styles/modules/layout.module.css';
import SEO from '../seo';

interface Props {
  title?: string;
  description?: string;
  image?: string;
  children: React.ReactNode;
}

const PageLayout = ({ title, description, image, children }: Props): JSX.Element => {
  return (
    <>
      <SEO title={title} description={description} image={image} />
      <main className={`${styles.container}`}>
        {children}
      </main>
    </>
  );
};

export default PageLayout;
